import React, { useState } from "react"

// Structural Imports
import Layout from "@components/structure/Layout.jsx"

// Component Imports
import BlueLine from "@components/utility/BlueLine.jsx"

// SEO
import Seo from "@components/utility/Seo.jsx"
import "@styles/pages/_homepage.scss"

// Images imports
import XywavLogo from "@images/xywav_logo.svg"

const Index = () => {



  return (
    <Layout>
      <Seo
        canonical="/"
        title="Oxybate Treatment for Cataplexy and EDS in Narcolepsy |XYREM®"
        description="XYREM® (sodium oxybate) is approved for use in adults and children ages 7 and older to help treat cataplexy and EDS in narcolepsy. See XYREM® prescribing information & BOXED Warning about serious side effects, CNS depression, misuse and abuse."
      />
      <div className="home-wrapper">
        <section className="container-md home-section-one">
          <h1 className="blueHeading">XYREM: an oxybate treatment for 2 common narcolepsy symptoms</h1>
          <p className="bodyCopy">XYREM is approved to treat cataplexy and/or excessive daytime sleepiness (EDS) in patients 7&nbsp;years of age and older with narcolepsy. It is taken at night to help relieve these symptoms that occur during the day. </p>
        </section>
        <section className="container-md home-section-two">
          <BlueLine width="100%" maxWidth="683px" />
          <h2 className="blueSubheading" style={{ marginTop: '1.5625rem' }}>It’s time to consider the sodium content in your narcolepsy medication</h2>
          <p className="bodyCopy mb-24">Some oxybate treatments for cataplexy and/or EDS in narcolepsy contain high levels of sodium. With <b>~1640 mg of sodium</b> at the maximum 9-g nightly dose, <b>high&#8209;sodium oxybates</b> like XYREM and LUMRYZ<sup>&trade;</sup> (sodium oxybate) for extended&#8209;release oral suspension <b>have even more sodium than the American Heart Association’s (AHA’s) recommendation for an entire day.</b></p>
          <BlueLine width="100%" maxWidth="683px" />
          <h3 className="mediumBlueSubheading" style={{ maxWidth: '37.25rem' }}>The AHA recommends less than 1500&nbsp;mg of sodium as the ideal daily limit for most adults. </h3>
          <BlueLine width="100%" maxWidth="683px" />
        </section>
        <section className="home-section-three home-pink-gradient">
          <div className="container-md">
            <div className="home-section-three-container">
              <div className="xywav-logo-container">
                <a href="https://www.xywav.com" target="_blank" rel="noopener" data-open-modal="true">
                  <img src={XywavLogo} alt="XYWAV® logo" />
                </a>
                <p className="bodyCopy"><b>With only 131&nbsp;mg of sodium at the equivalent 9&#8209;g nightly dose, XYWAV contains 92%&nbsp;less sodium than all high&#8209;sodium oxybates.</b></p>
              </div>
              <p className="red-purple-text-gradient text-24">Choosing XYWAV over high&#8209;sodium oxybates can help treat your symptoms of cataplexy and/or EDS in narcolepsy, while also reducing your sodium intake.</p>
              <a href="https://www.xywav.com/narcolepsy" target="_blank" rel="noopener" className="button button--red nowrap-mobile"  data-open-modal="true">Learn more about xywav</a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Index
